/**
 * @LastUpdate 2023-03-02 (YYYY-MM-DD) by @Vitor
 * @title This is a base class for all notifications (V2). It is not meant to be used directly.
 * @description If you want to create a new notification, you should extend this class and override the constructor.
*/
module.exports = class NotificationUtils {
    /**
     * @title This is the constructor for the NotificationUtils class.
     * @description This constructor should not be called directly. It is meant to be called by the constructor of the child class.
    */
    constructor() {
        this.name = 'Default'; // This is the name of the notification. It is used to identify the notification.
        this.type = 'System'; // This is the type of the notification. It is used to identify the notification.
        this.creationDate = new Date(); // This is the creation date of the notification.
        this.details = {
            action: '/', // This is the action that the notification will perform when clicked.
            author: 'System', // This is the author of the notification.
            text: 'Undefined', // This is the text (description) of the notification.
        };
    }
    /**
     * @description Getter for the name property.
    */
    get name() {
        return this._name;
    }
    /**
     * @description Getter for the type property.
     * @returns {string} The type of the notification.
    */
    get type() {
        return this._type;
    }
    /**
     * @description Setter for the details property.
     * @returns {string} The details of the notification.
    */
    get details() {
        return this._details;
    }
    /**
     * @description Getter for the creationDate property.
     * @returns {string} The creation date of the notification.
    */
    get creationDate() {
        return this._creationDate;
    }
    /**
     * @description Setter for the name property.
     * @param {string} name - The name of the notification.
    */
    set name(name) {
        this._name = name;
    }
    /**
     * @description Setter for the type property.
     * @param {string} type - The name of the notification.
    */
    set type(type) {
        this._type = type;
    }
    /**
     * @description Setter for the details property.
     * @param {string} details - The name of the notification.
    */
    set details(details) {
        this._details = details;
    }
    /**
     * @description Setter for the actions from Details property.
     * @param {string} details - The name of the notification.
    */
    set action(action) {
        this._details.action = action;
    }
    /**
     * @description Setter for the author from Details property.
     * @param {string} author - The name of the notification.
    */
    set author(author) {
        this._details.author = author;
    }
    /**
     * @description Setter for the text from Details property.
     * @param {string} text - The name of the notification.
    */
    set text(text) {
        this._details.text = text;
    }
    /**
     * @description Getter for the creationDate property.
     * @returns {string} The creation date of the notification.
    */
    set creationDate(creationDate) {
        this._creationDate = creationDate;
    }

    /**
     * @description This method is used to get the notification as an object.
     * @returns {object} The notification as an object.
    */
    get object () {
        return {
            name: this.name,
            creationDate: this.creationDate,
            type: this.type,
            details: this.details,
            seen: false,
            unread: true,
        }
    }
};
