<template>
    <div class="">
        <div class="">
            <va-input
            v-model="orderName"
            required
            readonly
            placeholder="Mandatory Field"
            >
            <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-lock"
            />
            </va-input>

            <va-input
            v-model="title"
            required
            placeholder="Title"
            >
            <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-envelope-o"
            />
            </va-input>

            <va-input
            v-model="link"
            required
            placeholder="Link (ex: https://www.ahajournals.org"
            >
            <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-link"
            />
            </va-input>

            <va-card title="Resource Image"  v-show="isSimple">
                <va-file-upload
                        class="flex xs12"
                        :fileTypes="acceptedFiles"
                        type="single"
                        v-model="upload"
                />
            </va-card>

            <va-checkbox
            v-model="isSimple"
            :label="ImageLabel"
            title="Will disable resource link"
            class="mb-3"
            />

            <va-checkbox
              v-model="useImageAsLink"
              label="Use Download URL from Image / PDF File (Optional)"
              title="Will disable resource link"
              class="mb-3"
            />

            <div class="flex flex-center">
                <va-button
                @click="editResource"
                :disabled="!allRequirementsMet"
                >
                <i
                    class="va-icon fa fa-file-audio-o"
                    style="margin-right: 1em;"
                />  Edit Resource
                </va-button>
            </div>
        </div>
    </div>
  
  </template>
  <script>
  import {debounce} from 'lodash';
  import moment from 'moment';
  import firebase from 'firebase';
  import axios from '@/scripts/interceptor.js'
  import LogCreator from "../../class/LogCreator"
  export default {
    data() {
      return {
        link: "",
        title: "",
        isSimple: false,
        order: 1,
        orderName: "Order: 0",
        upload: [],
        ImageLabel: "Attach Image / PDF File",
        acceptedFiles: [
            //add images format
            'image/jpeg',
            'image/png',
            'application/pdf',
        ],
        loading: false,
        useImageAsLink: false,
      };
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
      allRequirementsMet(){
        if(!this.isSimple){
          if(this.title && this.link){
            return true;
          }
        }else{
          if(this.title && (this.link  || this.useImageAsLink) && this.upload.length > 0 ){
            return true;
          }
        }
      },
    },
    async mounted() {
      this.order = this.data?.order
      this.orderName = `Order: ${this.data?.order}` 
      this.title = this.data?.title
      this.link = this.data?.URL
      this.ImageLabel = this.data?.imageUrl ? "Change Attached Document" : "Attach Image / PDF File"
    },
    methods: {
        validateField(field, isUrl = false){
          if(field.length > 1 || field != ""){
              if(isUrl){
                const regexURL = /(https?|ftp|file):\/\/[-A-Z0-9+&@#/%=~_|$?!:,.]*[A-Z0-9+&@#/%=~_|$]/i;
                if(!regexURL.test(field)){
                  throw new Error(`Oops, looks like you didn't enter a valid URL - (${field})`)
                }
                else{
                  return true;
                }
              }
              else{
                return true;
              }
          }
          else{
           throw new Error(`Field is empty - ${field}`)
          }
        },
       async startTransaction(){
          //check if doc with this.data.id exists
          let doc = await firebase.firestore().collection('resources').doc(this.data?.id).get()
          if(!doc.exists){
            throw new Error(`Resource with id ${this.data.id} does not exist`)
          }
          if(!this.isSimple){
            this.validateField(this.title)
            this.validateField(this.link, true)
            this.validateField(this.order)
            return await firebase.firestore().collection('resources').doc(this.data?.id).update({
              title: this.title,
              URL: this.link,
              updatedAt: moment().format(),
            }).then((docRef) => {
              this.$swal({
                title: 'Success!',
                text: 'Resource with id ' + this.data?.id + ' updated successfully',
                icon: 'success',
                confirmButtonText: 'Ok'
              }).then(() => {
                return true;
              })
            }).catch((error) => {
              throw new Error(error.message)
            })
          }else{
            //validate image
            let resourceImage = this.upload[0]
            let fileName = resourceImage.name
            this.validateField(this.title)
            this.validateField(this.link, true)
            this.validateField(this.order)
            this.validateField(fileName)
            if(!this.useImageAsLink)
              this.validateField(this.link, true)

            //remove whitespaces and lowercase image name
            fileName = fileName.replace(/\s/g, '').toLowerCase()
            //save image in storage
            const ref = firebase.storage().ref().child('/resources/images/' + fileName);
            const imageURL = await ref.put(resourceImage).then(async snapshot => {
              return snapshot.ref.getDownloadURL((url) => {
                return url;
              })
            })
            //file is pdf
            let isPDF = resourceImage.type === 'application/pdf' ? true : false
            return await firebase.firestore().collection('resources').doc(this.data?.id).update({
              title: this.title,
              URL: (isPDF && this.useImageAsLink) ? imageURL : this.link,
              imageUrl: imageURL,
              imageRef: '/resources/images/' + fileName,
              updatedAt: moment().format(),
            }).then((docRef) => {
              this.$swal({
                title: 'Success!',
                text: 'Resource created successfully',
                icon: 'success',
                confirmButtonText: 'Ok'
              }).then(() => {
                return true;
              })
            }).catch((error) => {
              throw new Error(error.message)
            })
          }
       },
       async editResource(){ 
        this.$swal({
          title: 'Are you sure?',
          text: "You are about to edit the resource with id: " + this.data?.id,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Edit'
        }).then(async (result) => {
          if (result.value) {
            this.loading = true;
            try {
              await this.startTransaction()
              this.$emit('updated')
            } catch (error) {
              this.$swal({
                title: 'Oops!',
                text: error.message,
                icon: 'warning',
                confirmButtonText: 'Ok'
              })
            }
          }
        })
      }
    },
}
 </script>
  